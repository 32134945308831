<template>
  <v-container class="app-page app-solicitar">
    <v-card class="overflow-hidden text-center">
      <h1 class="titulo">Planificar Licencia</h1>
      <v-form ref="licenciasForm" class="form-licencia" @submit.prevent="createSolicitud">
        <v-dialog
          ref="dialogLicencia"
          v-model="modal"
          :return-value.sync="object.desde"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="desdeFormatted"
              label="Desde"
              prepend-icon="event"
              readonly
              v-bind="attrs"
              v-on="on" 
              :rules="[rules.required]"
              class="input-date"
            ></v-text-field>
          </template>

          <!-- No me funciono, ver si se puede hacer andar esto -->
          <!-- <template v-slot:prepend>
             <v-img width="10" height="10" src="@/assets/app-page/calendar.svg"></v-img>
          </template>-->

          <v-date-picker
            v-model="object.desde"
            scrollable
            @input="modal = false; $refs.dialogLicencia.save(object.desde)"
          ></v-date-picker>
        </v-dialog>
        <v-text-field
          v-model="object.cantDias"
          label="dias"
          placeholder="cantidad de días"
          required
          class="input-importe"
          type="number"
          :rules="[rules.required, rules.number]"
        ></v-text-field>
        <v-text-field
          v-model="object.mensaje"
          label="observaciones"
          placeholder="observaciones"
          required
          class="input-aclaracion"
          :rules="[rules.required]"
        ></v-text-field>
        <v-select
          v-model="object.idContrato"
          :items="collections.contratos"
          :item-text="item => item.NA + ' ->' + item.Cargo"
          item-value="Id_Contrato"
          label="Nº contrato"
          placeholder="selecciona el contrato"
          :rules="[rules.required]"
          class="input-tipo"
        ></v-select>
        <v-select
          v-model="object.idConcepto"
          :items="collections.tipos_licencia"
          item-text="Nombre"
          item-value="ID_Concepto"
          label="solicitar"
          placeholder="selecciona el tipo de solicitud"
          :rules="[rules.required]"
          class="input-tipo"
        ></v-select>
        <v-btn
          type="submit"
          :disabled="saving"
          :loading="saving"
          class="btn primary mt-10"
        >Solicitar</v-btn>
      </v-form>

      <v-data-table
        :headers="computedHeaders"
        :items="items"
        :single-expand="true"
        :expanded.sync="expanded"
        show-expand
        item-key="idSolPLic"
        class="app-table"
        :hide-default-footer="true"
        mobile-breakpoint="0"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <v-simple-table class="app-table-child">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>N°</th>
                    <th>Fecha</th>
                    <th>Acción</th>
                    <th>Usuario</th>
                    <th>Memo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="record in item.lstLegajo" :key="record.idLegPL">
                    <td>{{ record.idLegPL }}</td>
                    <td>{{ formatDate(record.FecLeg) }}</td>
                    <td>{{ record.descEstLeg }}</td>
                    <td>{{ record.UsuNombre }}</td>
                    <td>{{ record.MensAcc }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>

        <template v-slot:item.fecSol="{ item }">{{ formatDate(item.fecSol) }}</template>

        <template v-slot:item.Desde="{ item }">{{ formatDate(item.Desde) }}</template>

        <template v-slot:item.estado="{ item }">
          <v-chip :color="getColor(item.estado)" dark>{{ item.estado }}</v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn x-small icon class="mr-2 btn-accion">
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </v-btn>
          <v-btn x-small icon class="mr-2 btn-accion">    
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-if="smallScreen" x-small icon class="btn-accion">    
            <v-icon small @click="verItem(item)">mdi-eye</v-icon>
          </v-btn>
        </template>
        <!-- <v-img src="@/assets/app-page/icon-ver.svg" @click="verItem(item)"></v-img> -->
        <!-- <v-img width="14px" src="@/assets/app-page/icon-trash.svg" @click="verItem(item)"></v-img> -->
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogAlert" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Seguro quieres eliminar la solicitud ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="dialogAlert = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" text @click="confirmDeleteItem()" :disabled="deleting" :loading="deleting">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUpdate" persistent max-width="390">
      <v-card>
        <v-card-title class="headline">Modificar Solicitud</v-card-title>
        <v-card-text>
          <v-form ref="licenciaUpdateForm" class="form-licencia">
            <v-dialog
              ref="dialog"
              v-model="updateModal"
              :return-value.sync="currentObject.desde"
              persistent
              dense
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="desdeUpdateFormatted"
                  label="Desde"
                  append-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="input-date" 
                  :rules="[rules.required]" 
                ></v-text-field>
              </template>

              <!-- No me funciono, ver si se puede hacer andar esto -->
              <!-- <template v-slot:prepend>
                <v-img width="10" height="10" src="@/assets/app-page/calendar.svg"></v-img>
              </template>-->

              <v-date-picker
                v-model="currentObject.desde"
                scrollable
                @input="updateModal = false; $refs.dialog.save(currentObject.desde)"
              ></v-date-picker>
            </v-dialog>
            <v-text-field
              v-model="currentObject.cantDias"
              label="dias"
              placeholder="cantidad de días"
              required
              class="input-importe"
              type="number"
              :rules="[rules.required, rules.number]"
            ></v-text-field>
            <v-text-field
              v-model="currentObject.mensaje"
              label="observaciones"
              placeholder="observaciones"
              required
              class="input-aclaracion"
              :rules="[rules.required]"
            ></v-text-field>
            <v-select
              v-model="currentObject.idContrato"
              :items="collections.contratos"
              :item-text="item => item.NA + ' ->' + item.Cargo"
              item-value="Id_Contrato"
              label="Nº contrato"
              placeholder="selecciona el contrato"
              :rules="[rules.required]" 
              readonly
              class="input-tipo"
            ></v-select>
            <v-select
              v-model="currentObject.idConcepto"
              :items="collections.tipos_licencia"
              item-text="Nombre"
              item-value="ID_Concepto"
              label="solicitar"
              placeholder="selecciona el tipo de solicitud"
              :rules="[rules.required]"
              class="input-tipo"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="dialogUpdate = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" text @click="updateLicencia()" :disabled="updating" :loading="updating">Modificar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogInfo" max-width="390" v-if="currentItem">
      <v-card>
        <v-card-title class="headline">Detalle de la Solicitud</v-card-title>
        <v-card-text>
            <v-text-field
              :value="currentItem.idSolPLic"
              label="ID"
              class="input-importe"
              dense
              readonly
            ></v-text-field>
            <v-text-field
              :value="currentItem.Id_Persona"
              label="Nº Cont"
              dense
              readonly
            ></v-text-field>
            <v-text-field
              :value="formatDate(currentItem.fecSol)"
              label="Solicitado"
              dense
              readonly
            ></v-text-field>
            <v-text-field
              :value="formatDate(currentItem.Desde)"
              label="Desde"
              dense
              readonly
            ></v-text-field>
            <v-text-field
              :value="currentItem.CantDias"
              label="Días"
              dense
              readonly
            ></v-text-field>
            <v-text-field
              :value="currentItem.descEstado"
              label="Estado"
              dense
              readonly
            ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialogInfo = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getLicencias, solicitarLicencia, actualizarLicencia, eliminarLicencia } from "@/api/core";
import { mapGetters } from "vuex";
import { hasPermission } from '@/utils/permission';

const _object = {
  desde: null,
  cantDias: null,
  idConcepto: null,
  idContrato: null,
  mensaje: null,
}

const _currentObject = {
  idSolicitud: null,
  desde: null,
  cantDias: null,
  idConcepto: null,
  idContrato: null,
  mensaje: null,
}

export default {
  name: "licencias",
  data: () => ({
    area: 8,
    smallScreen: false,
    dialogAlert: false,
    dialogUpdate: false,
    dialogInfo: false,
    loading: false,
    saving: false,
    deleting: false,
    updating: false,
    expanded: [],
    modal: false,
    updateModal: false,
    items: [],
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: false,
        value: "idSolPLic",
      },
      { text: "Nº Cont", align: "center", value: "Id_Persona" },
      //{ text: "Apellido", value: "apellido" },
      //{ text: "Nombre", value: "nombre" },
      { text: "Solicitado", value: "fecSol" },
      { text: "Desde", value: "Desde" },
      { text: "Días", value: "CantDias" },
      { text: "Estado", value: "descEstado", align: "center" },
      { text: "Acciones", value: "actions", sortable: false, align: "center" },
      { text: "", value: "data-table-expand" },
    ],

    filters: {
      estados: [],
      fechas: [],
      idEstadoSel: null,
      month: null,
    },

    object: Object.assign({}, _object),

    currentObject: Object.assign({}, _currentObject),

    currentItem: null,

    rules: {
      required: (value) => !!value || "Requerido.",
      number: (value) => {
        const pattern = /^([0-9]+)$/;
        return pattern.test(value) || "Monto invalido.";
      },
    },
  }),

  computed: {
    ...mapGetters(["user", "collections"]),

    desdeFormatted: {
      get() {
        return this.formatDate(this.object.desde)
      },
      set(val){
        return val
      }
    },

    desdeUpdateFormatted: {
      get() {
        return this.formatDate(this.currentObject.desde)
      },
      set(val){
        return val
      }
    },

    computedHeaders () {
      return this.headers.filter( item => {
        if(this.smallScreen)
          return item.value != 'idSolPLic' && item.value != 'Id_Persona' && item.value != 'fecSol'
        return true
      })
    },
  },

  watch: {
    'object.desde' () {
      this.desdeFormatted = this.formatDate(this.object.desde)
    },

    'currentObject.desde' () {
      this.desdeUpdateFormatted = this.formatDate(this.currentObject.desde)
    }
  },

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Planificación de Licencias");
    this.$store.commit("stopProcessing");
    this.smallScreen = window.innerWidth < 600
    if(!this.hasPermission(this.area)) {
      this.$router.go(-1)
    } else {
      this.initData()
    }
  },

  methods: {
    hasPermission,
    formatDate(datetime) {
      if (!datetime) return null;

      const [date, time] = datetime.split(" ");
      const [year, month, day] = date.split("-");
      if (time) return `${day}/${month}/${year} ${time}`;
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getColor(estado) {
      if (estado == "aprobado") return "aprobado";
      else if (estado == "pendiente") return "pendiente";
      else return "rechazado";
    },

    async initData() {
      try {
        this.loading = true

        const data = await getLicencias()

        this.items = data.records
        this.filters.estados = data.estados

        this.loading = false
      } catch(e) {
        console.log(e)
        this.loading = false
      }
    },

    async createSolicitud() {
      if (this.$refs.licenciasForm.validate(true)) {
        this.saving = true;
        const params = Object.assign({}, this.object);
        try {
          const data = await solicitarLicencia(params)
          const isError = Object.prototype.hasOwnProperty.call(data, "ErrNro") && data.ErrNro != 0
          this.$store.commit("setSnackBar", {
            message: isError ? data.ErrDescripcion : "Has solicitado la licencia correctamente!",
            show: true,
            color: isError ? "#cc0605" : "#008f39",
            timeout: 10000,
          })
          this.saving = false
          this.$refs.licenciasForm.resetValidation()
          this.cleanObject()
          if(!isError) this.initData()
        } catch (e) {
          this.saving = false
        }
      }
    },

    deleteItem(item) {
      this.currentObject.idSolicitud = item.idSolPLic
      this.dialogAlert = true
    },

    async confirmDeleteItem() {
      try {
        this.deleting = true
        let params = { idSolicitud: this.currentObject.idSolicitud }

        const data = await eliminarLicencia(params)

        const index = this.items.findIndex(item => item.idSolPLic == this.currentObject.idSolicitud )
        this.items.splice(index, 1)

        const isError = Object.prototype.hasOwnProperty.call(data, "ErrNro") && data.ErrNro != 0
        this.$store.commit("setSnackBar", {
          message: isError ? data.ErrDescripcion : "Has eliminado la solicitud correctamente!",
          show: true,
          color: isError ? "#cc0605" : "#008f39",
          timeout: 10000,
        })

        this.deleting = false
        this.dialogAlert = false
      } catch (e) {
        this.deleting = false
        this.dialogAlert = false
      }
    },

    async updateLicencia() {
      if(this.$refs.licenciaUpdateForm.validate(true)) {
        this.updating = true
        const params = Object.assign({}, this.currentObject);
        try {
          const data = await actualizarLicencia(params)
          const isError = Object.prototype.hasOwnProperty.call(data, "ErrNro") && data.ErrNro != 0
          console.log(isError)
          this.$store.commit("setSnackBar", {
            message: isError ? data.ErrDescripcion : "Has modificado la solicitud correctamente!",
            show: true,
            color: isError ? "#cc0605" : "#008f39",
            timeout: 10000,
          })
          this.$refs.licenciaUpdateForm.resetValidation()
          this.cleanCurrentObject()
          this.updating = false
          this.dialogUpdate = false
          if(!isError) this.initData()
        } catch(e) {
          this.updating = false
          this.dialogUpdate = false
        }
      }
    },

    editItem(item) {
      this.currentObject.idSolicitud = item.idSolPLic
      this.currentObject.desde = item.Desde
      this.currentObject.cantDias = item.CantDias
      this.currentObject.idConcepto = item.id_Concepto
      this.currentObject.idContrato = parseInt(item.Id_Contrato)
      this.currentObject.mensaje = null

      this.dialogUpdate = true
    },

    verItem(item) {
      this.currentItem = item

      this.dialogInfo = true
    },

    cleanObject() {
      this.object = Object.assign({}, _object)
    },

    cleanCurrentObject() {
      this.currentObject = Object.assign({}, _currentObject)
    }
  }
};
</script>

<style>
.v-dialog__content .input-date .v-icon{
  margin-top: 0 !important;
}
</style>